import { Controller } from "@hotwired/stimulus";
import intlTelInput from 'intl-tel-input/intlTelInputWithUtils';

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.initializePhoneInput();
  }

  initializePhoneInput() {
    return intlTelInput(this.inputTarget, {
      initialCountry: 'us',
      hiddenInput: () => ({
        phone: "phone_full",
        country: "country_code"
      }),
      loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.0/build/js/utils.js"),
    });
  }
}