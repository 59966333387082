require("@rails/ujs").start();
require("@rails/activestorage").start();
require("./channels");

var $ = require("jquery");
global.$ = global.jQuery = $;

import "bootstrap";
import "bootstrap-select/dist/js/bootstrap-select.js";

import flatpickr from "flatpickr";

import "./payment";
import "./suspension_length";
import "./reorder_fights";
import "./organization_form";
import "./event_user";
import "./event_organization";
import "./print_card";
import "./fight_form";

import "./controllers";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";

// support direct file uploads
ActiveStorage.start();

document.addEventListener("turbo:load", () => {
  flatpickr(".datepicker", { dateFormat: "m/d/Y", allowInput: true });

  $("[data-action=refreshPage]").on("click", () => window.location.reload());
});
