import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['link', 'countdown', 'message']

    static values = {
        nextCode: String
    }

    connect() {
        this.initCoundown();
        if (this.hasMessageTarget) {
            setTimeout(() => this.messageTarget.classList.add('d-none'), 3000)
        }
    }

    initCoundown() {
        if (!this.hasCountdownTarget)
            return;

        let remaining = parseInt(this.nextCodeValue, 10) || 0;
        const interval = setInterval(() => {
            remaining--;
            if (remaining <= 0) {
                clearInterval(interval);
                this.linkTarget.classList.remove('d-none');
                this.countdownTarget.textContent = '';
            } else {
                this.countdownTarget.textContent = `You can request a new code in ${remaining} seconds`;
            }
        }, 1000);


    }

}